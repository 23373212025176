// GeneralLayout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './css/style.css';

const GeneralLayout = ({ children }) => {
  return (
    <div className="general-layout">
      <Header />
      <div className="container">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default GeneralLayout;
