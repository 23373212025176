import React from 'react';
import { Link } from 'react-router-dom';
import './css/donaciones.css';

// Importar imágenes
import Downgrade from './images/dona/downgrade.png';
import Organos from './images/dona/organos.png';
import PackGemas from './images/dona/packcocinero.png';
import Ann from './images/dona/ann.png';
import PackHel from './images/dona/packhel.png';
import EditSocket from './images/dona/editsocket.png';
import Unsocket from './images/dona/unsocket.png';

const Donaciones = () => {
  return (
    <div className="donaciones-container">
      <div className="body-dona">
        <div className="container-dona">
          {/* <header className="header-dona">
            <h1>Donaciones</h1>
          </header> */}
          <nav className="nav-dona">
            <Link to="/donaciones/auras">Ver Auras</Link>
            <a href="https://api.whatsapp.com/send/?phone=5491154003420" rel="noopener noreferrer">Contacto</a>
            
            <Link to="/">Inicio</Link>
          </nav>

          
          <main className="main-dona">
            <section className="content-dona">
              <h2>¿Cómo donar?</h2>
              <ul>
      <li>Haz click en "Contacto".</li>
      <li>Realiza el pago utilizando MercadoPago, Transferencia o Paypal.</li>
      <li>*Las Donaciones se entregan +22:00 PM Arg*</li>
      <li>*El único que puede entregar items es Dubai*</li>
              </ul>



              
              <div className="grid-dona">
                <div className="grid-item">
                  <img src={Downgrade} alt="Downgrade" />
                  <p>Downgrade:</p>
                  <p>1 Cham = 1 Jah o Ber</p>
                  <p>A Elección</p>
                  <p>$2000 c/u o 10x7</p>
                </div>
                <div className="grid-item">
                  <img src={Organos} alt="Organos" />
                  <p>Set de Órganos x4</p>
                  {/* <p>$2000</p> */}
                  <p>Disponibles el 22/11</p>
                </div>
                <div className="grid-item">
                  <img src={PackGemas} alt="PackGemas" />
                  <p>Pack Craft x80</p>
                  <p>Amatistas+Ral+Joyas+Amu ilvl99</p>
                  <p>$3000</p>
                  <p>*Se puede elegir otra Piedra/Runa/Item*</p>
                </div>
                <div className="grid-item">
                  <p>Bolsa Gemas x100</p>
                  <p>x100 Piedras a elección</p>
                  <p>$2000</p>
                </div>
                <div className="grid-item">
                  <img src={Ann} alt="Ann" />
                  <p>Annuncios en el Juego</p>
                  <p>$2000</p>
                </div>

                <div className="grid-item">
                  <img src={EditSocket} alt="EditSocket" />
                  <p>Editar Cantidad de Socket</p>
                  <p>$2000</p>
                </div>
                <div className="grid-item">
                  <img src={Unsocket} alt="Unsocket" />
                  <p>Recuperar Runas/Joyas Engarzadas</p>
                  <p>$2500</p>
                </div>

                <div className="grid-item">
                  <img src={PackHel} alt="PackHel" />
                  <p>Pack Hel x10</p>
                  <p>$1000</p>
                </div>

                <div className="grid-item">
                <h3>Recuperar Portal Cow/Anya</h3>
                <p>$2500</p>
                  <h3>Desperzonalizar un Item</h3>
                  <p>$1000</p>
                  <h3>Token x4</h3>
                  <p>$1000</p>
                </div>


                <div className="grid-item">
                  <h3>Cambiar Nombre al PJ / Moverlo de cuenta</h3>
                  <p>$3500</p>
                </div>


                <div className="grid-item large">
                  <h3>Personajes Rusheados y Leveados</h3>
                  <div className="characters-container">
                    <div className="character-column">
                      <p>Personaje Nivel 80</p>
                      {/* <p>$2000</p> */}
                      <p>Disponibles el 22/11</p>
                    </div>
                    <div className="character-column">
                      <p>Personaje Nivel 85</p>
                      {/* <p>$2100</p> */}
                      <p>Disponibles el 22/11</p>
                    </div>
                    <div className="character-column">
                      <p>Personaje Nivel 90</p>
                      {/* <p>$2250</p> */}
                      <p>Disponibles el 22/11</p>
                    </div>
                  </div>
                </div>



                <div className="grid-item">
                  <h3>Combinar Cofre de Únicos</h3>
                  <p>$4000</p>
                </div>



                <div className="grid-item large">
                  <h3>Hechizos con Aura</h3>
                  <p>(También se pueden usar en Mercenarios)</p>
                  <p>Vienen con 20 Vida + 5 Todas las Resistencias</p>
                  {/* <p>1 Aura $1500 - 2 Auras $1700 - 3 Auras $2000</p> */}
                  <p>Disponible a partir del 22/11</p>
                  <Link to="/donaciones/auras" className="auras-link">Ver Lista de Auras</Link>

                  
                </div>


                <div className="grid-item">
                  <h3>Combinar Bolsa de Runas</h3>
                  <p>$4000</p>
                </div>


                {/* <div className="grid-item">
                  <h3>Emotes (Ver en lista de Auras, se castea como skill)</h3>
                  <p>$2000</p>
                </div> */}
                <div>  </div>
              </div>              
              <div className="pricing-table">
                <table>
                <thead>
                    <tr>
                    <th style={{ textAlign: 'center', fontSize: '1.3em'}}>Exclusivo sistema de Rolleo de Items</th>


                      <th></th>
                    </tr>
                  </thead>

                  <thead>
                    <tr>
                      <th>Requisitos:</th>
                      <th>Precio:</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>Entregas: Amu Craft o Tiara + Jah. Obtenes: Cambiar Tipo de Habilidad (Ej: 2 Elementales a 2 Druida).</td>
                      <td>$ 2.800</td>
                    </tr>
                  <tr>
                      <td>Entregas: Amu Craft o Tiara. Obtenes: Cambiar Clase de Habilidad. (Ej: 2 Invocacion a 2 Elementales).</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Anillos BK Obtenes: 1 Soj.</td>
                      <td>$ 2.500</td>
                    </tr>
                    <tr>
                      <td>Entregas: Tu Torch. Obtenes: Cambio de Clase con Mismos Stats. Ejemplo Druida 20/15 x Sorc 20/15</td>
                      <td>$ 2.500</td>
                    </tr>
                    <tr>
                      <td>Cambio de Clase de Gc HP (solo se aceptan Trampas, Cold, Fire, Rayo y Combat Pala. El resto de clases NO debe ser Baal Charm.).</td>
                      <td>$ 2.500</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Df &gt; = 23% Obtenes: DF 30% Con las resis del Mejor DF que hayas dado.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Df &gt; = 20% Obtenes: Grifo 20/-15.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Grifos NO Eth Obtenes: Grifo 20/-15.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 1 Df &gt; = 16% Obtenes: Schuta 3/20.</td>
                      <td>$ 2.500</td>
                    </tr>
                    <tr>
                      <td>Entregas: 1 Df &gt; = 23% Obtenes: Schuta 3/20/20.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: Bases con ED solo armas Merce Acto 2 (Ejemplo Coloso x Trilladora).</td>
                      <td>$ 2.000</td>
                    </tr>
                    <tr>
                      <td>Entregas: Chaco &gt; = 140 Def 2 Skill = Chaco 98 o 141 a elección.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Joya Ias + Resis = Cambiar Resis.</td>
                      <td>$ 2.300</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Maras &gt; = 25 Obtenes: Mara de 30</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Sands ETH (cualquiera). Obtenes: Sands Eth 15/15</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Visor de Andy 10/30. Obtenes 1 Visor de Andy 10/30 ETH</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 Eschutas ETH cualquier DMG + Eschuta 20 DMG Común. Obtenes: Eschuta 20 DMG ETH.</td>
                      <td>$ 2.800</td>
                    </tr>
                    <tr>
                      <td>Entregas: 2 DF Eth + Tu DF NO eth. Obtenes: TU DF Eth</td>
                      <td>$ 2.500</td>
                    </tr>
                    <tr>
                      <td>Cambio de Clase GC Plano: Entregas Cualquier GC Plano – Recibís Cualquier GC Plano a Elección</td>
                      <td>$1000 c/u o 4x $3000</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center', fontSize: '1.0em'}}>"&gt; ="" Significa que el item debe ser Mayor o Igual a </th>
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>
            </section>
          </main>
          <footer className="footer-dona">
            <p>&copy; 2024 Donaciones</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Donaciones;
