import React, { useEffect } from 'react';
import Segundo from './images/Segundo.png';
import Tercero from './images/Tercero.png';
import Cuarto from './images/Cuarto.png';
import Quinto from './images/Quinto.png';
import Sexto from './images/Sexto.png';
import Septimo from './images/Septimo.png';
import Primero from './images/Primero.png';
import './css/style.css';

const imageSources = [Primero, Segundo, Tercero, Cuarto, Quinto, Sexto, Septimo];

const ImageSwitcher = () => {
  useEffect(() => {
    let currentImageIndex = 0;
    const imgElement = document.getElementById('img-switcher');

    const switchImage = () => {
      if (imgElement) {
        imgElement.style.opacity = 0;
        setTimeout(() => {
          currentImageIndex = (currentImageIndex + 1) % imageSources.length;
          imgElement.src = imageSources[currentImageIndex];
          imgElement.style.opacity = 1;
        }, 500); // Duración del fade out antes de cambiar la imagen
      }
    };

    const intervalId = setInterval(switchImage, 3000); // Cambiar la imagen cada 3 segundos

    return () => clearInterval(intervalId);
  }, []);

  return <img id="img-switcher" src={imageSources[0]} alt="" />;
};

export default ImageSwitcher;
