import React from 'react';
import './css/style.css';

const Footer = () => {
  return (
    <footer>
      <div className="marquee">
        <span>Desarrolado por Guido Favara -- Hall Of Fame: Campeón Multclass = eG-Zoro Campeón 2vs2 = eG-Zoro + eG-Silhoutte -- Campeón Sorc = eG-Chiterita -- Campeón Pala Melee y Caster = eG-Agressive & eG-Griffindor -- Campeón Barbaros Melee = eG-Toro -- Campeón 2vs2 Sett = eG-Chiterita & eG-AutoAim.</span>
      </div>
    </footer>
  );
};

export default Footer;
