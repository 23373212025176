// SubWebLayout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './css/cambios.css';

const CambiosLayout = ({ children }) => {
  return (
    <div className="cambios-layout">
      <Header />
      <div className="cambios-container">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default CambiosLayout;
