// DonacionesLayout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './css/donaciones.css';

const DonacionesLayout = ({ children }) => {
  return (
    <div className="donaciones-layout">
      <Header />
      <div className="donaciones-container">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default DonacionesLayout;
